import React, { Component } from 'react';
import './mobileApp.css';
class MutualFundsComponent extends Component{
    render() 
    {    
        return ( 
           < React.Fragment> 
           <div class="imgs"> 
           <span class="width ml-5" ><img  src="/assets/images/mobilecharg.png"  /></span>
           </div>
           <div class="ENTERPRISE">
           <span class="width ml-5" ></span>
           </div>
           </React.Fragment>
        )

}
}

export default MutualFundsComponent;