import React, { Component } from 'react';


import './home.css';
class ContactComponent extends Component{
  
    render() 
    { 
      
        return ( 
           < React.Fragment> 
            <div className="row   col-md-12">
            <div className=" col-*">
            <div className=" contactimg col-*">
  <div className="row ">
<div className="  col-md-6">
  <br />
<span  className=" specheader col-* "> Customer Service </span><br /><br/>
<span  className=" spec col-*   "> Phone: +91-7982029314</span><br />
<span  className=" spec col-*  ">E-mail:support@gogedi.com</span>
{/*<span className="col-*-10 contactButton "  > CONTACT US </span>*/}

</div>
<div   className="  col-md-6">
<br />
<span  className=" specheader col-*  "> Business Relations </span><br/><br/>
<span  className=" spec col-* "> Phone: +91-9953760745</span><br />
<span  className=" spec col-*  "> E-mail: info@gogedi.com</span><br />
{/*<span  className="col-*-12 contactButton2 "  > CONTACT US </span>*/}


</div>
              
</div>
              
            </div>
            
             </div>
           
             </div>

            


            
           </React.Fragment>
        )

}
}

export default ContactComponent;