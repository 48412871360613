import React, { Component } from 'react';
import { BrowserRouter as Redirect} from "react-router-dom";
import Navbar from './customer/customernavbar.component';
import Fotterbar from './customer/customerfooter.component';
import HomeComponent from './customer/home.component';
import ContactComponent from './customer/contact.component';
import PrivacyPolicyComponent from './customer/privacypolicy.component';
import TermConditionsComponent from './customer/termconditions.component';

import BlogComponent from './customer/blog.component';
import ProductServicesComponent from './customer/productservices.component';
import SignUpComponent from './customer/signup.component';
import SignInComponent from './customer/signin.component';
import MutualFundsComponent from './customer/mutualfunds.component';
import './customer/navbar.css';

// minHeight : '45vh',maxHeight : '50vh', overflowX: 'scroll'
class CustomerRouterComponent extends Component {
  
   render() {
      
      return ( 
            <div>

{this.props?.location?.pathname === '/customer/PrivacyPolicy' && ( <main > 
            <div style={{}} >
            {this.openPolicyPage()}
            </div>
            </main>)}
            {  this.props?.location?.pathname === '/customer/TermConditions' && ( <main > 
            <div style={{}} >
            {this.openPolicyPage()}
            </div>
            </main>)}
            {this.props?.location?.pathname !== '/customer/PrivacyPolicy' && this.props?.location?.pathname !== '/customer/TermConditions' && ( <div className="window-main-screen">
             <div classname="navigation">
              <Navbar />
             </div>
            <div>
            <main > 
            <div style={{}} >
            {this.openPage()}
            </div>
            </main>
            </div>
             
             <div >
            <Fotterbar  />
             </div>
      </div>)}</div>
       );
   }

   openPage =() => {
      let htmlele=""
      console.log(this.props.location.pathname);

         switch (this.props.location.pathname) {
             case "/customer/MobileApp":
                 htmlele=<MutualFundsComponent  />
                  break;
            case "/customer/servicesproducts":
                  htmlele=<ProductServicesComponent />
                  break;
            case "/customer/Enterprise":
                  htmlele=<BlogComponent />
                  break;
            case "/customer/contact":
                  htmlele=<ContactComponent />
                  break;
                
            case "/customer/signup":
                  htmlele=<SignUpComponent />
                  break;
            case "/customer/signin":
                  htmlele=<SignInComponent />
                  break;
            case "/customer/home":
                   htmlele=<HomeComponent />
                     break;
            default: 
                  htmlele=<Redirect to="/customer/home" />
                        
            }
      return htmlele
   }
   openPolicyPage =() => {
      let htmlele=""
      console.log(this.props.location.pathname);

         switch (this.props.location.pathname) {
           
                  case "/customer/PrivacyPolicy":
                        htmlele=<PrivacyPolicyComponent />
                        break;
                        case "/customer/TermConditions":
                              htmlele=<TermConditionsComponent />
                              break;
          
            default: 
                  htmlele=<Redirect to="/customer/home" />
                        
            }
      return htmlele
   }
}
 
export default CustomerRouterComponent;