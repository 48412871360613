import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './footerbar.css';

 class Fotterbar extends Component  {
	 state={
		open:false,
		 setOpen:false
	 }
    PrivacyPolicyWindow = () => {
    // specify the URL you want to open in the new window
    const url = '/customer/PrivacyPolicy';

    // open the new window with the specified URL
    window.open(url);
  };
  TermConditionsWindow = () => {
    // specify the URL you want to open in the new window
    const url = '/customer/TermConditions';

    // open the new window with the specified URL
    window.open(url);
  };
	
render(){
	return (
		<div className="card footbar">
		   <div className=" col-md-12 footer_main d-flex justify-content-center"  >
            <div className="row col-md-12">
            
		
            <div className="    pt-4">
             <div className=" get_in_touch_content   ">
               <span className=" pb-3 policy_procedure_text d-flex justify-content-center">Delhi NCR D-32 , Aacharya niketan Mayur Vihar 1, Delhi - 110091</span>
               
			 <div className="get_in_touch_content pt-2 d-flex justify-content-center">
               <span className="policy_procedure_text">Email: info@gogedi.com</span>
               
             </div>
			 <div className="get_in_touch_content pt-2 d-flex justify-content-center">
               <span className="policy_procedure_text">Call Us +91-9310999140</span>
               
             </div>
			
			 
			 <div className=" pt-3 get_in_touch_content pt-2 d-flex justify-content-center">
               <span className="policy_procedure_text">Built with the latest technology GoGEDI  provides a complete SaaS platform for the <br/> operation, management and usage of Electric Vehicle Charging Stations using OCPP.</span>
               
             </div>
            </div>
			<div align=" right " className="right">
                  <a  ><Link to="#" onClick={this.PrivacyPolicyWindow}>Privacy Policy</Link></a>
                  <a  ><Link to="#" onClick={this.TermConditionsWindow}>&nbsp;&nbsp;&nbsp;&nbsp;  Terms & Conditions</Link></a>
                 <br /><br />
                </div>
           </div>
		  
         </div>
		 
		</div>
		</div>
		
	);
}
 }
export default Fotterbar;