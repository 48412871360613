import React, { Component } from 'react';

import './privacy.css';

class TermConditionsComponent extends Component{
  
    render() 
    { 
      
        return ( 
           < React.Fragment> 
            <div className="row   col-md-12">
            <div className=" col-*">
            <div className="  col-* d-flex justify-content-center">
           
            <div class="container">
            <header>
        <h1>Terms & Conditions</h1>
    </header>
    <section className='mt-4'>
            
                 <p> We value the trust you place in us. That's why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following statement to learn about our information gathering and dissemination practices.</p>

        </section>
        <section className='mt-4'>
            
            <p><strong>Note:</strong> Our privacy policy (Privacy Policy) is subject to change at any time without notice. To make sure you are aware of any changes, please view this Privacy Policy periodically.</p>
           
        </section>
        <section className='mt-4'>
            
            <p>By visiting this App, you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree, please do not use or access our APP. By mere use of the APP, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is subject to our Terms of Use.</p>
           
        </section>

        <section className='mt-4'>
            <h2 ><strong>1.</strong> COLLECTION OF OTHER INFORMATION</h2>
            <p><strong>1.1 </strong> When you use our APP, we collect and store your personal information and other information (except payment mode related details) which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our APP to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.</p>
            <p><strong>1.2 </strong> You can go through the APP which require you to provide your personal information including but 
not limited to your name, contact details, vehicle details etc. Once you give us your personal
information, you are not anonymous to us. Where possible, we indicate which fields are required and
which fields are optional. You have the option not to provide information by choosing not to use the APP.
We may automatically track certain information about you based upon your behavior on our APP. We
might use this information to do internal research on our users' demographics, interests and behavior to
better understand, protect and serve our users. This information is compiled and analyzed on an aggregated basis. Your personal information may also be analyzed to provide you customized
information, or information with a customized approach on our application.</p>

            <p><strong>1.3</strong> We collect personally identifiable information (email address, name, phone number etc.) from you
when you set up an account with us. While you may be able to browse some sections of our portal
without being a registered member, access to certain activities/features do require registration. We
reserve the right to use your contact information to send you offers based on your previous activity and
your interests. </p>
        </section>
       
        <section className='mt-4'>
            <h2 ><strong>2.</strong> USE OF INFORMATION</h2>
            <p><strong>2.1 </strong> We use personal information to provide the services you request. To the extent we use your personal
information to market to you, we will provide you the ability to opt-out of such uses. We use your
personal information to resolve disputes; troubleshoot problems; help promote a safe service; collect
money; measure consumer interest in our services, inform you about online and offline offers, products,
services, and updates; customize your experience; detect and protect us against error, fraud and other
criminal activity; enforce our terms and conditions; and as otherwise described to you at the time of
collection.</p>
            <p><strong>2.2 </strong> In our efforts to continually improve our service offerings, we collect and analyze demographic and
profile data about our users' activity on our APP. We identify and use your IP address to help diagnose
problems with our server, and to administer our app. Your IP address and location, is also used to help
identify you and to gather broad demographic information.</p>

            <p><strong>2.3</strong> We may occasionally ask you to complete optional online surveys. These surveys may ask you for
contact information and demographic information (like pin code, age, or income level). We use this data
to tailor your experience at our portal, providing you with content that we think you might be interested
in and to display content according to your preferences. </p>
        </section>
        <section className='mt-4'>
            <h2 ><strong>3.</strong> SHARING OF INFORMATION</h2>
            <p><strong>3.1 </strong> We may share personal information with our other corporate entities and affiliates to help detect
and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple accounts
to prevent abuse of our services. Those entities and affiliates may not market to you as a result of such
sharing unless you explicitly opt-in.</p>
            <p><strong>3.2 </strong> We may disclose personal information if required to do so by the Indian laws or in the good faith
belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal
process. We may disclose personal information to law enforcement offices, third party rights owners, or
others in the good faith belief that such disclosure is reasonably necessary to enforce our Terms of Use
or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of
a third party; or protect the rights, property or personal safety of our users or the general public.</p>

        </section>

        <section className='mt-4'>
            <h2 ><strong>4.</strong> LINKS TO OTHER PORTALS</h2>
            <p>Our portal links to payment portals (that may collect personally identifiable information about you). We
and our portal are not responsible for the privacy practices or the content of those linked portals.
Whatever data is transmitted to those other portals like Credit Card/Debit Card information, BHIM
information, UPI information etc. that you as a user provide to these other payment portals are
protected under the data prevention policies of those portals itself and do not come under the purview
of our data protection policy. On request we provide access to the data prevention policies of the linked
portals to our users.</p>
            

        </section>

        <section className='mt-4'>
            <h2 ><strong>5.</strong> SECURITY PRECAUTIONS</h2>
            <p>Our portal/APP has security measures in place to protect the loss, misuse, and alteration of the
information under our control. Once your information is in our possession we adhere to security
guidelines, protecting it against unauthorized access.</p>
           

        </section>
        <section className='mt-4'>
            <h2 ><strong>6.</strong> CHOICE/OPT-OUT</h2>
            <p>We will provide all users with the opportunity to opt-out of receiving non-essential (promotional,
marketing-related) communications from us (if at all you receive such communications in the future),
after setting up an account.</p>
           

        </section>
        <section className='mt-4'>
            <h2 ><strong>7.</strong> ADVERTISEMENTS</h2>
            <p>We may use third-party advertising companies to serve ads when you visit our APP. These companies
may use information (not including your name, address, email address, or telephone number) about
your visits to this and other portals/APP in order to provide advertisements about goods and services of
interest to you.</p>
           

        </section>
        <section className='mt-4'>
            <h2 ><strong>8.</strong> YOUR CONSENT</h2>
            <p>By using the portal and/or or app and/ or by providing your information, you consent to the collection and use of the information you disclose on the portal and/or software application in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this Privacy Policy. If we decide to change our Privacy Policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose.</p>
           

        </section>
        <section className='mt-4'>
            <h2 ><strong>9.</strong> REFUND POLICY</h2>
            <p>No Refund Policy.</p>
           

        </section>
    </div>
              
            </div>
            
             </div>
           
             </div>

            


            
           </React.Fragment>
        )

}
}

export default TermConditionsComponent;