import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

 class Navbar extends Component  {
	
	state={
		open:false,
		 setOpen:false
	 }

	handleClick = () => {
		this.state.setOpen=!this.state.open;
	};

	closeMenu = () => {
		this.state.setOpen=false;
	};



render(){
	return (
		<nav className="navbar col-* pl-0">
			<div onClick={this.handleClick()} className="nav-logo ml-0 col-lg-3">
			<Link to="/customer/home" >
				<img alt="" className="pt-0 mt-0 ml-0 pl-0" src={process.env.PUBLIC_URL + "/assets/images/logo-final.png"} width="150px"/>
			</Link>
			
			</div>
			<div className="link navigation-links col-lg-6">
				
			<ul className={this.state.open ? 'nav-links active' : 'nav-links'}>
				
				<li className="nav-item"><a href="#">
						SOLUTIONS
					</a>
			    <div class="dropdown">
				<ul>
			    <li ><a  href="#"><Link to="/customer/home">CLOUD - CPMS </Link></a></li>
				<li><a href="#"><Link to="/customer/Enterprise">ENTERPRISE  - WHITELABEL</Link> </a></li>
				<li><a href="#"><Link to="/customer/MobileApp">CONNECT - MOBLE APP</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">EV FLEET MANAGEMENT </Link></a></li>
				<li><a href="#"><Link to="/customer/home">EROAMING</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">VENDORS & MODELS</Link> </a></li>
				</ul>
				</div>
				</li>
				
				<li className="nav-item">
						ABOUT
					<div class="dropdown">
				<ul>
			    <li><a  href="#"><Link to="/customer/home">ABOUT GoGEDI</Link></a></li>
				<li><a href="#"><Link to="/customer/home">FAQ</Link> </a></li>
				
				
				
				</ul>
				</div>
				</li>
				<li className="nav-item">
					<Link to="/customer/home" className="nav-link" onClick={this.closeMenu()}>
						RESOURCES
					</Link>
				</li>
				{/*<li className="nav-item">
					<Link to="/customer/blog" className="nav-link" onClick={this.closeMenu()}>
						BLOG
					</Link>
	</li>*/}

            <li className="nav-item">
					<Link to="/customer/contact" className="nav-link" onClick={this.closeMenu()}>
						CONTACT&nbsp;US
					</Link>
				</li>

				
			</ul>
			</div>
		{/*<div className="sign_up_container  d-flex justify-content-center col-md-2" >
			<span  onClick={this.handleClick()} className="sign_up">
			<Link to="/customer/signup" >
			SIGN UP
			</Link>
			</span>
	</div>*/}
	<div className="sink">
	    <li className="buttonable">
		<Link  className="nav-link" onClick={this.closeMenu()}>
						<img src="/assets/images/menuicon.jpg" width={80} />
					</Link>
					
					<div class="dropmenu  ">
				<ul>
			    <li><a  href="#"><Link >SOLUTIONS</Link></a>
				<div class="menulist">
				<ul>
			    <li ><a  href="#"><Link to="/customer/home">CLOUD - CPMS </Link></a></li>
				<li><a href="#"><Link to="/customer/Enterprise">ENTERPRISE  - WHITELABEL</Link> </a></li>
				<li><a href="#"><Link to="/customer/MobileApp">CONNECT - MOBLE APP</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">EV FLEET MANAGEMENT </Link></a></li>
				<li><a href="#"><Link to="/customer/home">EROAMING</Link> </a></li>
				<li><a href="#"><Link to="/customer/home">VENDORS & MODELS</Link> </a></li>
				<br />
				</ul>
				</div>
				</li>
				<li><a  href="#"><Link>ABOUT</Link></a>
				
				<div class="menulist">
				<ul>
			    <li><a  href="#"><Link to="/customer/home">ABOUT GoGEDI</Link></a></li>
				<li><a href="#"><Link to="/customer/home">FAQ</Link> </a></li>
				
				<br />
				
				</ul>
				</div>
				</li>
			    <li><a  href="#"><Link to="/customer/home">RESOURCES</Link></a></li>

				<li><a href="#"><Link to="/customer/contact">CONTACT US</Link> </a></li>
				<br />
				
				
				</ul>
				</div>
				
				</li>
				</div>
		</nav>
		
		
	);
}
};

export default Navbar;