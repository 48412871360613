import React, { Component } from 'react';
import './home.css';
import { Link } from 'react-router-dom';
class HomeComponent extends Component{
    render() 
    { 
        return ( 
           < React.Fragment> 
           <div className="homeSize col-*">
           <div className="row   col-sm-12">
            <div className="row  col-sm-12 mt-0 mb-0 ml-0 mr-0">
            <div className="row evimg col-sm-12 d-flex justify-content-center mt-2 mb-0 pb-0 ml-0 mr-0">
              <span  className=" fontColor col-sm-8   d-flex justify-content-center mb-0 pb-0 mt-0 pt-2 pr-0 "> 
              
              </span>
             
              <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /><br /><br /><br /><br /><br /><br /><br /><br />
              <span align=" right "  className="fontColorss  col-sm-10  "> 
              GoGEDI Offers
              </span>
              <span align=" right " className="col-sm-10 mt-4  fontColorsss   justify-content-center">
              
              A Unified EV Charging  Solution  
              </span>
              <span className="col-sm-10  fontColorss d-flex justify-content-center ">
              
              </span>
              <br /> <br /><br /><br />
              <span align=" right " className="col-sm-12 mb-0 mt-0 pt-0 pr-0 mb-3 ">
              <button className="col-sm-12 cta " ><Link className="Link" to="/customer/contact"> CONTACT US </Link></button>
              
              </span>
              
            </div>
             </div>
             <div className="col-sm-7  mt-0 pt-1 mr-0 pr-0">
             
             </div>
             </div>
            
           
           <div className="col-sm-12 evcolor mt-0 pt-1 mr-0 pr-0">
            <div className="col-sm-12 Accelerate d-flex justify-content-center" > Accelerate your EV Charging Business</div><br />
           <p className="col-sm-12 Specifi d-flex justify-content-center" > GoGedi specializes in latest technology for the management, operation and usage of Electric</p>
           <p className="col-sm-12 Specifi d-flex justify-content-center" >Vehicle Charge Points. We provide adaptable solutions for charge point owners, resellers and organizations.</p><br />
           <p className="col-sm-12  d-flex justify-content-center"></p>
           </div>
           <div className=" row col-sm-12 ">
           <div className=" padding-left col-md-6 ">
            <br /> <br />
           <span className="col-sm-6 Accelerate "> EV Charging Management Software</span>
           <p  className="col-sm-12 pera ">We have implemented  user friendly methods for the 
           operation of charge points and simplified the process for the locating, charging and payment of
            EV charging. With our easy to use and reliable sofware solutions drivers can confidently choose 
            Electric Vehicles charging station and Charge Point Operators can manage their charge stations with ease.</p>
           </div>
           <div  className="col-sm-6 evcharging"><img src="/assets/images/evcharging.jpg" width={650} /></div>

           </div>

            

           </div>
            
           
           </React.Fragment>
        )
}
}

export default HomeComponent;