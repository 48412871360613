import React, { Component } from 'react';

import './privacy.css';

class PrivacyPolicyComponent extends Component{
  
    render() 
    { 
      
        return ( 
           < React.Fragment> 
            <div className="row   col-md-12">
            <div className=" col-*">
            <div className="  col-* d-flex justify-content-center">
           
            <div class="container">
            <header>
        <h1>Privacy Policy</h1>
    </header>
        <section className='mt-4'>
            <h2 ><strong>1.</strong> Description</h2>
            <p><strong>SERVICE</strong> means the info@gogedi.com website and Gogedi Mobile application operated by GOGEDI MOBILITY PVT. LTD.</p>
            <p><strong>PERSONAL DATA</strong> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
            <p><strong>USAGE DATA</strong> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
            <p><strong>COOKIES</strong>  are small files stored on your device (computer or mobile device).</p>
            <p><strong>DATA CONTROLLER</strong> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
            <p><strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>
            <p><strong>THE USER</strong> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>

        </section>

        <section className='mt-4'>
            <h2 ><strong>2.</strong> Information Collection and Use</h2>
            <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
           
        </section>
        <section className='mt-4'>
            <h2 ><strong>3.</strong> Types of Data Collected</h2>
            <p><li><strong>Personal Data :</strong> While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</li></p>
            <p><strong>a)</strong> Email address</p>
            <p><strong>b)</strong> First name and last name</p>
            <p><strong>c)</strong> Phone number</p>
            <p><strong>d)</strong> Address</p>
            <p><li><strong>Usage Data :</strong> Usage Data is collected automatically when using the Service.Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</li></p>
            <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
            <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
            <p><li><strong>Location Data :</strong> We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.</li></p>
            <p>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>
            <p><li><strong>Tracking Cookies Data :</strong> We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</li></p>
            <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</p>
            <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            <p><strong>Examples of Cookies we use:</strong></p>
            <p><strong>a) Session Cookies:</strong> We use Session Cookies to operate our Service.</p>
            <p><strong>b) Preference Cookies:</strong> We use Preference Cookies to remember your preferences and various settings.</p>
            <p><strong>c) Security Cookies:</strong> We use Security Cookies for security purposes.</p>
            <p><strong>d) Advertising Cookies:</strong> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</p>

        </section>
        <section className='mt-4'>
            <h2 ><strong>4.</strong>Use of Data</h2>
            <p><strong>GOGEDI MOBILITY PVT. LTD.</strong> uses the collected data for various purposes:</p>
            <p>To provide and maintain our Service, including to monitor the usage of our Service.</p>
            <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
            <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
            <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application’s push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
            <p>To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
            <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
            <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
            <p><strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
            <p><strong>We may share Your personal information in the following situations:</strong></p>
            <p><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</p>
            <p><strong>For business transfers:</strong> strong We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</p>
            <p><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</p>
            <p><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</p>
            <p><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</p>
            <p><strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with Your consent.</p>
        </section>
        <section className='mt-4'>
            <h2 ><strong>5.</strong> Retention of Data</h2>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
           <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
        </section>
        <section className='mt-4'>
            <h2 ><strong>6.</strong> Transfer of Data</h2>
            <p>Your information, including Personal Data, may be transferred to – and maintained on –computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
           <p>If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there.</p>
           <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
           <p><strong>GOGEDI MOBILITY PVT.LTD.</strong> will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
        </section>
        <section className='mt-4'>
            <h2 ><strong>7.</strong> Disclosure of Data</h2>
            <p><strong>Business Transactions</strong></p>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
            <p><strong>Law enforcement</strong></p>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
            <p> <strong>Other legal requirements</strong></p>
            <p><strong>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</strong></p>
            <p>Comply with a legal obligation</p>
            <p>Protect and defend the rights or property of the Company</p>
            <p>Prevent or investigate possible wrongdoing in connection with the Service</p>
            <p>Protect the personal safety of Users of the Service or the public</p>
            <p>Protect against legal liability</p>
            </section>
            <section className='mt-4'>
            <h2 ><strong>8.</strong> Security of Data</h2>
            <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
             </section>
             <section className='mt-4'>
            <h2 ><strong>9.</strong> Payments</h2>
            <p>We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
            <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands</p>
            <p>like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
             </section>
             <section className='mt-4'>
            <h2 ><strong>10.</strong> Links to Other Sites</h2>
            <p>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
             <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
             </section>
             <section className='mt-4'>
            <h2 ><strong>11.</strong> Children’s Privacy</h2>
            <p>Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).</p>
            <p>We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>
             </section>
             <section className='mt-4'>
            <h2 ><strong>12.</strong> Changes to This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
            <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
             </section>
             <section className='mt-4'>
            <h2 ><strong>13.</strong> Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us by email: <strong>support@gogedi.com</strong></p>
             </section>
    </div>
              
            </div>
            
             </div>
           
             </div>

            


            
           </React.Fragment>
        )

}
}

export default PrivacyPolicyComponent;